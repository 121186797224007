import { ColumnOriginType, RowOriginType, RowType, SortState } from '@C/components/common/ui/tables/Table/types';
import { OrdersTableData } from '@/components/tables/OrdersTable/types';
import {
    StatusBlockIcon,
    StatusCanceledIcon,
    StatusDoneIcon,
    StatusExecutionIcon,
    StatusNewIcon,
    StatusRescheduledIcon
} from '@C/assets/icons';
import { OrdersQueryVariables, SortOrder } from '@/graphql/api.types';
import { ProductType } from '@/components/contents/Order/Product/types';
import { VarnishIcon } from '@/components/tables/OrdersTable/styles';

export const typeByStatusMapper: { [key in string]: RowType } = {
    Заблокирована: 'block',
    'Повторный цикл': 'warning',
    Отменен: 'error',
    'В работе': 'success',
    Завершен: 'info',
    'Макет согласован': 'info',
    'Монтаж подготовлен': 'info',
    Новый: 'default'
};

export const iconByStatusMapper: { [key in string]: JSX.Element } = {
    Заблокирована: <StatusBlockIcon />,
    'Повторный цикл': <StatusRescheduledIcon />,
    Отменен: <StatusCanceledIcon />,
    'В работе': <StatusExecutionIcon />,
    Завершен: <StatusDoneIcon />,
    'Макет согласован': <StatusDoneIcon />,
    'Монтаж подготовлен': <StatusDoneIcon />,
    Новый: <StatusNewIcon />
};

export const getIconByVarnish: (varnish: ProductType['varnish']) => JSX.Element | null = varnish =>
    varnish === null ? null : <VarnishIcon isActive={varnish} />;

export const columns: ColumnOriginType<OrdersTableData>[] = [
    { Header: '', accessor: 'icon', width: 30, disableSortBy: true },
    { Header: '№', accessor: 'id', width: 30 },
    { Header: '№ Изделия', accessor: 'productId', width: 70 },
    { Header: 'Клиент', accessor: 'name', width: 100 },
    { Header: 'Изделие', accessor: 'productName', width: 110 },
    { Header: 'Дата сдачи заказа', accessor: 'date', width: 100 },
    { Header: 'Тираж', accessor: 'batchSize', width: 40 },
    { Header: 'Бумага', accessor: 'materialFormat', width: 80 },
    { Header: 'Материал', accessor: 'material', width: 100 },
    { Header: 'Кол-во листов', accessor: 'batchSizePrintedSheets', width: 80 },
    { Header: 'Формат', accessor: 'printFormat', width: 80 },
    { Header: 'Цветность', accessor: 'colorfulness', width: 100 },
    { Header: 'Лак', accessor: 'varnish', width: 30, disableSortBy: true },
    { Header: 'Кол-во на листе ', accessor: 'piecesPerPrintedSheetCount', width: 80 },
    { Header: 'Статус', accessor: 'status', width: 80 },
    { Header: 'Кол-во этапов в заказе', accessor: 'productSchedulesLength', width: 80, disableSortBy: true },
    { Header: 'Этап', accessor: 'operationName' }
];

export const rowTypeFn = (row: RowOriginType<OrdersTableData>) => typeByStatusMapper[row.status];

export const orderByColumnMapperBySortState: (
    sort: SortOrder
) => Record<keyof OrdersTableData, OrdersQueryVariables['orderBy']> = sort => ({
    icon: undefined,
    id: { orderId: sort },
    productId: { id: sort },
    name: { order: { client: { name: sort } } },
    productName: { name: sort },
    date: { order: { deadlineAt: sort } },
    batchSize: { batchSize: sort },
    materialFormat: { material: { format: { sort } } },
    material: { material: { name: sort } },
    batchSizePrintedSheets: { batchSizePrintedSheets: { sort } },
    printFormat: { printFormat: { sort } },
    colorfulness: { colorfulness: { sort } },
    varnish: undefined,
    piecesPerPrintedSheetCount: { piecesPerPrintedSheetCount: { sort } },
    status: { order: { status: { name: sort } } },
    productSchedulesLength: undefined,
    operationName: { productionDetail: { operationStatus: { schedule: { operation: { name: sort } } } } }
});

export const getOrderByColumn: (
    params?: SortState<OrdersTableData>
) => OrdersQueryVariables['orderBy'] | undefined = params => {
    const { sortState, id } = params || {};

    if (sortState === 'none' || !sortState || !id) {
        return undefined;
    }

    const sort = sortState === 'asc' ? SortOrder.Asc : SortOrder.Desc;

    return orderByColumnMapperBySortState(sort)[id];
};
