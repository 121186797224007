import { Combobox, ComboboxProps } from '@C/components/common/inputs/Combobox';
import { ComboboxLoadFn } from '@C/components/common/inputs/Combobox/types';
import { useClientsLazyQuery } from '@/graphql/api.types';
import { useEffect } from 'react';
import { mapClientsToOption } from '@/components/inputs/ClientsCombobox/constants';
import { portalRootSelector } from '@/constants/common';

type ClientsComboboxProps = Omit<ComboboxProps, 'defaultOptions' | 'loadFn'>;

export const ClientsCombobox = (props: ClientsComboboxProps) => {
    const [loadClients, { data }] = useClientsLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        refetchWritePolicy: 'overwrite',
        variables: {
            skip: 0,
            take: 10,
            search: ''
        }
    });

    const defaultClients = mapClientsToOption(data).slice(0, 10);

    const loadClientsFn: ComboboxLoadFn = (search, cb) => {
        loadClients({ variables: { search, skip: 0, take: 10 } }).then(({ data }) => cb(mapClientsToOption(data)));
    };

    useEffect(() => {
        loadClients();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onInputChange = (newValue: string) => {
        if (newValue === '') {
            loadClients();
        }
    };

    return (
        <Combobox
            portalSelector={portalRootSelector}
            defaultOptions={defaultClients}
            loadFn={loadClientsFn}
            onInputChange={onInputChange}
            {...props}
        />
    );
};
