import { NoopType, OnUpdateInterface, ReactSelectOption } from '@C/types/data';
import { Form } from '@/components/forms/OrderForm/styles';
import { FormInputs, InputWrapper } from '@/components/forms/StageForm/styles';
import { Row } from '@C/components/wrappers/grid/FlexWrapper';
import { MarginWrapper } from '@C/components/wrappers/grid/MarginWrapper';
import { Button } from '@C/components/common/buttons/Button';
import { useEffect, useId, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { stageMeta, yupSchema } from '@/components/forms/StageForm/constants';
import { ProductScheduleInterface, StageInputs } from '@/components/forms/StageForm/types';
import { TimePicker } from '@C/components/common/inputs/Timepicker';
import { DatePicker } from '@C/components/common/inputs/Datepicker';
import { portalRootSelector } from '@/constants/common';
import moment, { Moment } from 'moment/moment';
import { TextField } from '@C/components/common/inputs/TextField';
import { OperationsSelector } from '@/components/inputs/OperationsSelector';
import { notify, NotifyContent } from '@C/components/common/ui/Notifications';
import {
    useCreateScheduleMutation,
    useMachineByOperationLazyQuery,
    useUpdateScheduleMutation
} from '@/graphql/api.types';
import {
    defaultDate,
    defaultDateTimeFormat,
    defaultDateTimeServerFormat,
    defaultMomentDateTime
} from '@C/constants/dateTime';
import { convertDurationInMinToMoment } from '@C/utils/dateTimeUtils';
import { Selector } from '@C/components/common/inputs/Select';
import { requiredFieldMessage } from '@/constants/validation/messages';
import { usingValue } from '@/utils/forms';

interface StageFormProps extends ProductScheduleInterface, OnUpdateInterface {
    onClose: NoopType;
    type?: 'new' | 'edit' | 'editScheduler';
    productId: number;
}

export const StageForm = ({ onClose, type = 'new', onUpdate, productId, productSchedule }: StageFormProps) => {
    const id = useId();
    const [loading, setLoading] = useState(false);
    const [equipmentOption, setEquipmentOption] = useState<ReactSelectOption[]>([]);

    const isNew = type === 'new',
        isEdit = type === 'edit',
        isEditScheduler = type === 'editScheduler';

    const [createScheduleMutation] = useCreateScheduleMutation();
    const [updateScheduleMutation] = useUpdateScheduleMutation();
    const [loadMachineByOperation] = useMachineByOperationLazyQuery({
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-and-network'
    });

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { isValid, isDirty, errors }
    } = useForm<StageInputs>({ resolver: yupResolver(yupSchema), mode: 'onChange' });

    // @ts-ignore
    const stageValue = watch('stage');
    const equipmentValue = watch('equipment');
    const startDateValue = watch('startDate');
    const startTimeValue = watch('startTime');
    const durationValue = watch('duration');
    const durationInMinutes = moment
        .duration(moment(`${defaultDate} ${durationValue}`, defaultDateTimeFormat).diff(defaultMomentDateTime))
        .asMinutes();
    const hasEquipmentError = equipmentOption.length > 0 && !equipmentValue?.value;
    const hasStartDateError = startTimeValue && !startDateValue;
    const hasStartTimeError = startDateValue && !startTimeValue;
    const [startHour, startMinute] = (startTimeValue || '').split(':');
    const startAtMoment =
        startTimeValue && startDateValue
            ? (startDateValue as Moment).set({ hours: Number(startHour), minutes: Number(startMinute), second: 0 })
            : undefined;

    const onSubmit: SubmitHandler<StageInputs> = async values => {
        try {
            setLoading(true);

            const { equipment, stage, comment } = values;

            const machineData = usingValue(equipment?.value, {
                machine: {
                    connect: {
                        id: Number(equipment?.value)
                    }
                }
            });

            if (isNew) {
                const returnedCreateSchedule = await createScheduleMutation({
                        variables: {
                            data: {
                                stageTime: durationInMinutes,
                                product: {
                                    connect: {
                                        id: productId || 0
                                    }
                                },
                                operation: {
                                    connect: {
                                        id: Number(stage.value)
                                    }
                                },
                                description: comment,
                                ...machineData
                            }
                        }
                    }),
                    createdSchedule = returnedCreateSchedule.data?.createOneSchedule;

                notify.success(
                    <NotifyContent
                        title="Новый этап успешно создан"
                        text={
                            <>
                                Этап <b>{createdSchedule?.operation.name || ''}</b> создан
                            </>
                        }
                    />
                );
            }
            if (isEdit || isEditScheduler) {
                const descriptionValue = usingValue(
                    comment,
                    {
                        description: {
                            set: comment
                        }
                    },
                    {
                        description: {
                            set: null
                        }
                    }
                );

                const startAtValue = usingValue(
                    startAtMoment,
                    {
                        startAt: {
                            set: startAtMoment?.clone()?.utc().format(defaultDateTimeServerFormat)
                        }
                    },
                    {
                        startAt: {
                            set: null
                        }
                    }
                );

                await updateScheduleMutation({
                    variables: {
                        scheduleId: productSchedule?.id || 0,
                        data: {
                            stageTime: {
                                set: durationInMinutes
                            },
                            ...startAtValue,
                            ...descriptionValue,
                            ...machineData
                        }
                    }
                });

                notify.info(
                    <NotifyContent
                        title="Этап успешно изменен"
                        text={
                            <>
                                Этап <b>{productSchedule?.operation.name || ''}</b> изменен
                            </>
                        }
                    />
                );
            }

            onClose();
        } catch (e) {
            const message = (e as { message: string })?.message;
            notify.error(<NotifyContent title={message || 'Что-то пошло не так'} />);
        } finally {
            onUpdate && onUpdate();
            setLoading(false);
        }
    };

    useEffect(() => {
        if (productSchedule) {
            const { stageTime, operation, machine, description, startAt } = productSchedule;

            if (machine) {
                setValue('equipment', { label: machine?.name, value: String(machine?.id) });
            }

            // @ts-ignore
            setValue('stage', { label: operation.name, value: String(operation.id) });
            setValue('duration', convertDurationInMinToMoment(stageTime).format('HH:mm'));
            setValue('comment', description || '');

            if (startAt) {
                const startAtMoment = moment(startAt);
                setValue('startDate', startAtMoment);
                setValue('startTime', startAtMoment.format('HH:mm'));
            }
        }
    }, [productSchedule, setValue]);

    useEffect(() => {
        if (stageValue) {
            loadMachineByOperation({ variables: { operationId: Number(stageValue.value) } }).then(({ data }) => {
                const equipmentOption = (data?.operation?.machines || []).map(({ name, id }) => ({
                    label: name,
                    value: String(id)
                }));
                setEquipmentOption(equipmentOption);
                const equipmentValue =
                    !isNew && productSchedule?.machine?.name
                        ? { label: productSchedule?.machine?.name || '', value: String(productSchedule?.machine?.id) }
                        : null;

                setValue('equipment', equipmentValue);
            });
        } else {
            setEquipmentOption([]);
            setValue('equipment', null);
        }
    }, [
        isNew,
        loadMachineByOperation,
        productSchedule?.machine?.id,
        productSchedule?.machine?.name,
        setValue,
        stageValue
    ]);

    useEffect(() => {
        if (startTimeValue && startDateValue) {
            const endAtMoment = startAtMoment?.clone()?.add(durationInMinutes, 'minutes') as Moment;
            setValue('endDate', endAtMoment);
            setValue('endTime', endAtMoment?.format('HH:mm'));
        } else {
            setValue('endDate', null);
            setValue('endTime', null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDateValue, startTimeValue, durationInMinutes]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormInputs>
                <InputWrapper>
                    {/* @ts-ignore */}
                    <Controller
                        render={({ field: { onChange, value } }) => {
                            return (
                                <OperationsSelector
                                    onChange={onChange}
                                    value={value}
                                    label={stageMeta.stage.label}
                                    placeholder={stageMeta.stage.placeholder}
                                    errorText={errors.stage?.message}
                                    required
                                    portalSelector={portalRootSelector}
                                    inputTheme="secondary"
                                    isClearable
                                    disabled={isEdit || isEditScheduler}
                                />
                            );
                        }}
                        control={control}
                        name="stage"
                    />
                </InputWrapper>
                <InputWrapper>
                    <Controller
                        render={({ field: { onChange, value } }) => {
                            return (
                                <Selector
                                    value={value}
                                    onChange={onChange}
                                    label={stageMeta.equipment.label}
                                    placeholder={stageMeta.equipment.placeholder}
                                    portalSelector={portalRootSelector}
                                    inputTheme="secondary"
                                    options={equipmentOption}
                                    isClearable
                                    disabled={!equipmentOption.length || isEditScheduler}
                                    required={!!equipmentOption.length}
                                    errorText={hasEquipmentError ? requiredFieldMessage : undefined}
                                />
                            );
                        }}
                        control={control}
                        name="equipment"
                    />
                </InputWrapper>
                <InputWrapper>
                    <Controller
                        render={({ field: { onChange, value } }) => {
                            return (
                                <TimePicker
                                    id={`${id}-duration`}
                                    name={`${id}-duration`}
                                    errorText={errors.duration?.message}
                                    label={{
                                        hours: stageMeta.durationHour.label,
                                        minutes: stageMeta.durationMinute.label
                                    }}
                                    value={value}
                                    type="minutes"
                                    onChange={onChange}
                                    required
                                    disabled={isEditScheduler}
                                />
                            );
                        }}
                        control={control}
                        name="duration"
                    />
                </InputWrapper>
                {isEditScheduler && (
                    <>
                        <InputWrapper>
                            <Controller
                                render={({ field: { onChange, name, value } }) => {
                                    return (
                                        <DatePicker<Moment>
                                            onChange={onChange}
                                            label={stageMeta.startDate.label}
                                            value={value}
                                            id={`${id}-startDate`}
                                            name={name}
                                            minDate={moment().add(1)}
                                            returnedType="Moment"
                                            errorText={hasStartDateError ? requiredFieldMessage : undefined}
                                            inputTheme="secondary"
                                            autoComplete="off"
                                        />
                                    );
                                }}
                                control={control}
                                name="startDate"
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <Controller
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TimePicker
                                            id={`${id}-startTime`}
                                            name={`${id}-startTime`}
                                            errorText={hasStartTimeError ? requiredFieldMessage : undefined}
                                            label={{
                                                hours: stageMeta.startHour.label,
                                                minutes: stageMeta.startMinute.label
                                            }}
                                            type="minutes"
                                            onChange={onChange}
                                            value={value}
                                        />
                                    );
                                }}
                                control={control}
                                name="startTime"
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <Controller
                                render={({ field: { onChange, name, value } }) => {
                                    return (
                                        <DatePicker<Moment>
                                            onChange={onChange}
                                            label={stageMeta.endDate.label}
                                            value={value}
                                            id={`${id}-endDate`}
                                            name={name}
                                            minDate={moment().add(1)}
                                            returnedType="Moment"
                                            errorText={errors.endDate?.message}
                                            inputTheme="secondary"
                                            disabled
                                        />
                                    );
                                }}
                                control={control}
                                name="endDate"
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <Controller
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TimePicker
                                            id={`${id}-endTime`}
                                            name={`${id}-endTime`}
                                            errorText={errors.endTime?.message}
                                            label={{
                                                hours: stageMeta.endHour.label,
                                                minutes: stageMeta.endMinute.label
                                            }}
                                            type="minutes"
                                            value={value}
                                            onChange={onChange}
                                            disabled
                                        />
                                    );
                                }}
                                control={control}
                                name="endTime"
                            />
                        </InputWrapper>
                    </>
                )}
                <InputWrapper>
                    <TextField
                        id={`${id}-comment`}
                        type="textarea"
                        label={stageMeta.comment.label}
                        {...register('comment')}
                        errorText={errors.comment?.message}
                    />
                </InputWrapper>
            </FormInputs>
            <Row width="100%" justifyEnd>
                <MarginWrapper marginRight="10px">
                    <Button onClick={onClose} colorTheme="secondary">
                        Отмена
                    </Button>
                </MarginWrapper>
                <Button
                    disabled={!isValid || !isDirty || hasEquipmentError || hasStartDateError || hasStartTimeError}
                    loading={loading}
                    type="submit"
                >
                    Сохранить
                </Button>
            </Row>
        </Form>
    );
};
