import { Combobox, ComboboxProps } from '@C/components/common/inputs/Combobox';
import { ComboboxLoadFn } from '@C/components/common/inputs/Combobox/types';
import { useEffect } from 'react';
import { mapMachinesToOption } from '@/components/inputs/MachinesCombobox/constants';
import { useMachinesLazyQuery } from '@/graphql/api.types';

type MachinesComboboxProps = Omit<ComboboxProps, 'defaultOptions' | 'loadFn'>;

export const MachinesCombobox = (props: MachinesComboboxProps) => {
    const [loadMachines, { data }] = useMachinesLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        refetchWritePolicy: 'overwrite',
        variables: {
            skip: 0,
            take: 1000,
            search: ''
        }
    });

    const defaultMachines = mapMachinesToOption(data).slice(0, 5);

    const loadMachinesFn: ComboboxLoadFn = (search, cb) => {
        loadMachines({ variables: { search, skip: 0, take: 5 } }).then(({ data }) => cb(mapMachinesToOption(data)));
    };

    const onInputChange = (newValue: string) => {
        if (newValue === '') {
            loadMachines();
        }
    };

    useEffect(() => {
        loadMachines();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Combobox onInputChange={onInputChange} defaultOptions={defaultMachines} loadFn={loadMachinesFn} {...props} />
    );
};
