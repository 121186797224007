import { Form } from '@/components/forms/OrderForm/styles';
import { NoopType, OnUpdateInterface } from '@C/types/data';
import { useEffect, useId, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ClientInputs } from '@/components/forms/ClientForm/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { clientMeta, yupSchema } from '@/components/forms/ClientForm/constants';
import { FormInputs, InputWrapper } from './styles';
import { TextField } from '@C/components/common/inputs/TextField';
import { Row } from '@C/components/wrappers/grid/FlexWrapper';
import { MarginWrapper } from '@C/components/wrappers/grid/MarginWrapper';
import { Button } from '@C/components/common/buttons/Button';
import { useCreateClientMutation, useUpdateClientMutation } from '@/graphql/api.types';
import { notify, NotifyContent } from '@C/components/common/ui/Notifications';
import { ClientInterface } from '@/components/contents/Client/ClientContent/types';
import { portalRootSelector } from '@/constants/common';
import { ClientTypeSelector } from '@/components/inputs/ClientTypeSelector';
import { requiredFieldMessage } from '@/constants/validation/messages';

interface ClientFormProps extends ClientInterface, OnUpdateInterface {
    onClose: NoopType;
    type?: 'new' | 'edit';
}

export const ClientForm = ({ onClose, type, onUpdate, client }: ClientFormProps) => {
    const isEdit = type === 'edit';

    const id = useId();

    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        trigger,
        control,
        formState: { isValid, errors },
        watch
    } = useForm<ClientInputs>({ resolver: yupResolver(yupSchema), mode: 'onChange' });

    const clientTypeValue = watch('clientType'),
        innValue = watch('inn'),
        isEntitySelected = clientTypeValue?.value === '2',
        isInnValid = isEntitySelected ? !!innValue : true;

    const [createClient] = useCreateClientMutation();
    const [updateClient] = useUpdateClientMutation();

    const onSubmit: SubmitHandler<ClientInputs> = async ({ clientType, ...data }) => {
        try {
            setLoading(true);
            const clientTypeValue = { clientType: { connect: { id: Number(clientType.value) } } };

            if (isEdit) {
                const dataValue = (Object.keys(data) as Array<keyof Omit<ClientInputs, 'clientType'>>).reduce(
                    (value, key) => ({ ...value, [key]: { set: data[key] } }),
                    {}
                );

                await updateClient({
                    variables: {
                        clientId: client?.id,
                        data: { ...dataValue, ...clientTypeValue }
                    }
                });

                notify.info(
                    <NotifyContent
                        title="Клиент успешно изменен"
                        text={
                            <>
                                Клиент <b>{client?.id || ''}</b> изменен
                            </>
                        }
                    />
                );
            } else {
                const returnedCreateClient = await createClient({
                    variables: { data: { ...data, ...clientTypeValue } }
                });

                notify.success(
                    <NotifyContent
                        title="Клиент успешно создан"
                        text={
                            <>
                                Клиент <b>{returnedCreateClient.data?.createOneClient?.id || ''}</b> создан
                            </>
                        }
                    />
                );
            }

            onClose();
        } catch (e) {
            notify.error(<NotifyContent title="Что-то пошло не так" />);
        } finally {
            onUpdate && onUpdate();
            setLoading(false);
        }
    };

    useEffect(() => {
        if (client) {
            const { address, name, clientType, contact, contact2, contact3, inn, phone, email, documents } = client;

            setValue('address', address || '');
            setValue('name', name);
            setValue('clientType', { label: clientType?.type, value: String(clientType?.id) });
            setValue('contact', contact);
            setValue('contact2', contact2 || '');
            setValue('contact3', contact3 || '');
            inn && setValue('inn', inn);
            setValue('phone', phone);
            setValue('email', email);
            setValue('documents', documents || '');
        }
    }, [client, setValue]);

    useEffect(() => {
        if (isEdit) {
            trigger('name');
        }
    }, [isEdit, trigger]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormInputs>
                <InputWrapper>
                    <TextField
                        type="text"
                        id={`${id}-name`}
                        label={clientMeta.name.label}
                        placeholder={clientMeta.name.placeholder}
                        errorText={errors.name?.message}
                        {...register('name')}
                        required
                    />
                </InputWrapper>
                <InputWrapper>
                    <Controller
                        render={({ field: { onChange, value } }) => {
                            return (
                                <ClientTypeSelector
                                    required
                                    onChange={onChange}
                                    value={value}
                                    label={clientMeta.clientType.label}
                                    placeholder={clientMeta.clientType.placeholder}
                                    errorText={errors.clientType?.message}
                                    portalSelector={portalRootSelector}
                                    isClearable
                                />
                            );
                        }}
                        control={control}
                        name="clientType"
                    />
                </InputWrapper>
                <InputWrapper>
                    <TextField
                        type="text"
                        id={`${id}-inn`}
                        label={clientMeta.inn.label}
                        placeholder={clientMeta.inn.placeholder}
                        errorText={!isInnValid ? requiredFieldMessage : undefined}
                        required={isEntitySelected}
                        {...register('inn')}
                    />
                </InputWrapper>
                <InputWrapper>
                    <TextField
                        type="text"
                        id={`${id}-contact`}
                        label={clientMeta.contact.label}
                        placeholder={clientMeta.contact.placeholder}
                        errorText={errors.contact?.message}
                        {...register('contact')}
                        required
                    />
                </InputWrapper>
                <InputWrapper>
                    <TextField
                        type="text"
                        id={`${id}-contact2`}
                        label={clientMeta.contact2.label}
                        placeholder={clientMeta.contact2.placeholder}
                        errorText={errors.contact2?.message}
                        {...register('contact2')}
                    />
                </InputWrapper>
                <InputWrapper>
                    <TextField
                        type="text"
                        id={`${id}-contact3`}
                        label={clientMeta.contact3.label}
                        placeholder={clientMeta.contact3.placeholder}
                        errorText={errors.contact3?.message}
                        {...register('contact3')}
                    />
                </InputWrapper>
                <InputWrapper>
                    <TextField
                        type="text"
                        id={`${id}-phone`}
                        label={clientMeta.phone.label}
                        placeholder={clientMeta.phone.placeholder}
                        errorText={errors.phone?.message}
                        {...register('phone')}
                        required
                    />
                </InputWrapper>
                <InputWrapper>
                    <TextField
                        type="text"
                        id={`${id}-email`}
                        label={clientMeta.email.label}
                        placeholder={clientMeta.email.placeholder}
                        errorText={errors.email?.message}
                        {...register('email')}
                        required
                    />
                </InputWrapper>
                <InputWrapper>
                    <TextField
                        type="text"
                        id={`${id}-address`}
                        label={clientMeta.address.label}
                        placeholder={clientMeta.address.placeholder}
                        errorText={errors.address?.message}
                        {...register('address')}
                        required
                    />
                </InputWrapper>
                <InputWrapper>
                    <TextField
                        type="textarea"
                        id={`${id}-documents`}
                        label={clientMeta.documents.label}
                        placeholder={clientMeta.documents.placeholder}
                        errorText={errors.documents?.message}
                        {...register('documents')}
                        required
                    />
                </InputWrapper>
            </FormInputs>
            <Row width="100%" justifyEnd>
                <MarginWrapper marginRight="10px">
                    <Button onClick={onClose} colorTheme="secondary">
                        Отмена
                    </Button>
                </MarginWrapper>
                <Button disabled={!isValid || !isInnValid} loading={loading} type="submit">
                    {isEdit ? 'Сохранить' : 'Создать'}
                </Button>
            </Row>
        </Form>
    );
};
